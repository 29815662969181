<template>
  <div>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="blue"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title>Вход в панель управления</v-card-title>

      <v-card-text>
        <div>Не говорите никому свой пароль)</div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            v-model="loginName"
            :counter="10"
            :rules="[rules.required, rules.min]"
            label="Логин"
            required
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-1"
            label="Пароль"
            hint="At least 8 characters"
            counter
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-card-actions>
            <v-btn color="deep-purple lighten-2" type="submit"> Войти </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions, mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "Login",

  data: () => ({
    loading: false,
    loginName: "",
    password: "",
    showPassword: false,
    rules: {
      required: (value) => !!value || "Обязательное поле.",
      min: (v) => v.length >= 2 || "Хотя бы два знака",
    },
  }),
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  mounted() {
    this.onLoggedIn();
    this.getUser().then(() => {
      this.onLoggedIn();
    });
  },
  methods: {
    ...mapActions(["login", "getUser"]),
    submit() {
      this.loading = true;
      this.login({
        login: this.loginName,
        password: this.password,
      })
        .finally(() => {
          this.loading = false;
        })
        .then(() => {
          this.onLoggedIn();
        });
    },
    onLoggedIn() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "offers" });
      }
    },
  },
};
</script>
